:root {
  --main-background: hsl(255, 78%, 54%);
  --main-color: hsl(0, 0%, 100%);

  --alt-background: hsl(0, 0%, 100%);
  --alt-color: hsl(0, 10%, 10%);
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vh;
  font-size: 16px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

section {
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 76vh;
}

nav {
  margin: 0.3em 0;
}

main {
  padding: 1rem;
}

.btn {
  background-color: royalblue;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px navy;
  width: 7em;
  padding: 0.25em 0.75em;
  border-radius: 0.5em;
  margin: 0 0.5em;
  border: 2px solid powderblue;
  box-shadow: 2px 2px 3px black;
}

.btn:hover {
  background-color: navy;
}
.btn:focus {
  color: rgb(250, 200, 108);
  text-shadow: 1px 1px black;
  outline: none;
}
.btn:active {
  box-shadow: 0 0 1px black;
}

.header {
  background-color: var(--main-background);
  color: var(--main-color);
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4em;
  padding: 0.5em 1em;
}

.header h1 {
  text-shadow: 2px 2px 3px navy;
  margin: 0;
  padding: 0;
}
.content {
  background-color: var(--alt-background);
  color: var(--alt-color);
  padding: 0.5em 1em;
}
.footer {
  position: absolute;
  bottom: 0;
  background-color: var(--main-background);
  color: var(--main-color);
  display: flex;
  width: 100%;
  min-height: 2em;
  padding: 0.5em 1em;
}

.footer h6 {
  width: 100%;
  text-shadow: 2px 2px 3px navy;
  text-align: center;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact ul {
  list-style: none;
}

.contact li {
  display: flex;
  gap: 1rem;
}

.contact p,
.contact b,
.contact a {
  padding: 0.25rem 0;
}

.contact b {
  min-width: 10ch;
}

.home-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
  justify-content: center;
}
.home-section h3 {
  width: 100%;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  gap: 1rem;
  margin-bottom: 1rem;
}
.service-card {
  text-align: center;
  width: 100%;
  max-width: 300px;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  color: white;
  text-shadow: 2px 2px darkslategrey;
  line-height: 1.5rem;
  font-size: 0.9rem;
  box-shadow: 3px 3px 5px hsl(0, 0%, 0%);
}
.service-card h2 {
  margin-bottom: 1rem;
}
